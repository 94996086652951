import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#400d69';

const SpacelillyPage = ({ data }) => (
  <>
    <SEO
      title="Top NeoSurf Casino in Universe 2024 – Space Lilly"
      description="Visit one of the best Neosurf Casinos in 2024 – SpaceLilly Casino. Claim Welcome Bonus Package to boost your balance and get wager-free spins."
      alternateLanguagePages={[
        {
          path: 'casino/spacelilly',
          language: 'en'
        },
        {
          path: 'casino/spacelilly',
          language: 'fr'
        },
        {
          path: 'casino/spacelilly',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="The very best neosurf casino in the Universe 2024 – SpaceLilly" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">THE BEST NEOSURF CASINO IN UNIVERSE 2024 - SPACE LILLY</h1>
            <p className="textColorLight"><em>Space LILLY is a Casino that's just out of this world!</em></p>
            <p className="textColorLight">With amazing, space themed interface following Space LILLY heroine in her travels through the universe, it’s just a fun all-around Casino. Game selection is amazing, offering hundreds and hundreds of Slots and Table games from dozens of Game Providers and extensive Live Casino selection. Bonuses are just as amazing, ranging from various matching bonuses, Free Spins with no rollover to Cashbacks with symbolic rollover. There really is nothing not to like about LILLY!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">LILLY’S SPACE CASINO NEOSURF BONUS-PACKAGES IN 2024</h2>
            <p>Space Lilly Casino accepts Neosurf payments in 2024! Check out amazing offers from one of the top-rated NeoSurf Casinos in 2024.</p>
            <h3 className="smaller textUppercase">LILLY’s Out of This World WELCOME PACKAGE 1</h3>
            <ul className="textColorDark">
              <li>Deposit for the 1'st time and LILLY will reward you with 100% up to $/€ 150 and she will throw in 25 wager-free Free Spins!</li>
              <li>Deposit for the 2'nd time and LILLY will reward you with 20% up to $/€ 400 and she will throw in 50 wager-free Free Spins!</li>
              <li>Deposit for the 3'rd time and LILLY will reward you with 25% up to $/€ 450 and she will throw in 125 wager-free Free Spins!</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">TO SPACE LILLY</a>
            </p>
            <h3 className="smaller textUppercase">LILLY’s Out of This World WELCOME PACKAGE 2</h3>
            <ul className="textColorDark">
              <li>Deposit for the 1'st time and LILLY will reward you with 20% up to $/€ 200 Free Cashback!</li>
              <li>Deposit for the 2'nd time and LILLY will reward you with 20% up to $/€ 300 Free Cashback!</li>
              <li>Deposit for the 3'rd time and LILLY will reward you with 25% up to $/€ 500 Free Cashback!</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5" target="_blank" rel="noopener noreferrer">TO SPACE LILLY</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Welcome Bonus Packages available in 2024" />
        </div>
      </div>
    </div>
  </>
)

export default SpacelillyPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/spacelilly/neosurf_spacelilly.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/spacelilly/space-LILLY-EXPLORER-WELCOME-PACKAGE.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
